import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`PCA`}</h1>
    <ul>
      <li parentName="ul">{`Principal Components Analysis is a `}<a parentName="li" {...{
          "href": "/dimension-reduction",
          "title": "dimension reduction"
        }}>{`dimension reduction`}</a>{` algorithm`}</li>
      <li parentName="ul">{`This is one of the key `}<a parentName="li" {...{
          "href": "/Algorithms",
          "title": "👾 Algorithms"
        }}>{`👾 Algorithms`}</a>{` in `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`, and enables us to visualize `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` in relation to each other within the `}<a parentName="li" {...{
          "href": "/opinion-landscape",
          "title": "opinion landscape"
        }}>{`opinion landscape`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{`Participants are closer together in this landscape if they tend to agree.`}</li>
          <li parentName="ul">{`And further apart if they tend to disagree.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`See more on Wikipedia: `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Principal_component_analysis"
        }}>{`https://en.wikipedia.org/wiki/Principal_component_analysis`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      